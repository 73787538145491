import React, { useState, useEffect, useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import { Dropdown, Tooltip } from "flowbite-react";
import Link from "next/link";
import Image from "next/image";
import { getAuthToken, setCurrentUser } from "@src/utils/auth";
import ShareModel from "@src/components/common/ShareModel";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import logo from "@src/./static/img/treepixel-round-logo.svg";
//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard", "createsite"])),
    },
  };
}

function ShareDropdown(props) {
  const { t: translate } = useTranslation("createsite");
  const {
    siteUrl,
    language,
    showLabel,
    metadata,
    siteData,
    isLabel,
    userDetails,
  } = props;
  const [copyText, setCopyText] = useState("Copy");
  const handleCopyClick = () => {
    const textToCopy = process.env.BASE_URL + siteUrl || "-";
    navigator.clipboard.writeText(textToCopy);
    setCopyText(translate("Copied"));
  };
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    const temp = getAuthToken();
    if (temp) {
      setIsLogin(true);
    }
  }, []);
  return (
    <div className="">
      <ShareModel
        siteUrl={siteUrl}
        language={language}
        siteData={siteData}
        showLabel={showLabel}
        metadata={metadata}
        isLabel={isLabel}
        userDetails={userDetails}
      />
    </div>
  );
}
export default ShareDropdown;
