import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { Modal } from "flowbite-react";
import { Dropdown, Tooltip } from "flowbite-react";
import Link from "next/link";
import Image from "next/image";
import { getAuthToken, setCurrentUser } from "@src/utils/auth";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import logo from "@src/./static/img/treepixel-round-logo.svg";
//i18 multi-language
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { QRCode } from "react-qrcode-logo";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["dashboard", "createsite"])),
    },
  };
}

const ShareModel = (props) => {
  const { locale } = useRouter();
  const {
    siteUrl,
    language,
    qrCodeImage,
    metadata,
    showLabel,
    size,
    siteData,
    isLabel,
    userDetails,
  } = props;

  const { t: translate } = useTranslation("createsite");
  const [copyText, setCopyText] = useState("Copy");
  const [showShareModel, setShowShareModel] = useState(false);
  const [customDomainTabOn, setCustomDomainTabOn] = useState(false);
  // const [isInnerPage, setIsInnerPage] = useState(false);
  const [userPlanType, setUserPlanType] = useState("");
  const handleCopyClick = () => {
    const textToCopy = customDomainTabOn
      ? "www." + siteData?.custom_domain_name
      : process.env.BASE_URL + siteUrl || "-";
    navigator.clipboard.writeText(textToCopy);
    setCopyText(translate("Copied"));
  };

  useEffect(() => {
    // let pathName = window.location.pathname;
    // if (pathName === "/my-site") {
    //   setIsInnerPage(true);
    // }

    // let getUserDetails = JSON.parse(window.localStorage.getItem("userDetails"));
    const planType = metadata?.subscription_status
      ? metadata?.subscription_status
      : userDetails?.subscription_status;
    setUserPlanType(planType);
  }, [userDetails]);

  useEffect(() => {
    switchShareTab("morlinkClick");
  }, []);

  const onShowModel = () => {
    setShowShareModel(true);
  };

  const onCloseModel = () => {
    setShowShareModel(false);
  };

  const switchShareTab = (buttonEvent) => {
    // console.log("buttonEventbuttonEvent ", buttonEvent);
    if (buttonEvent == "customdomainClick") {
      setCustomDomainTabOn(true);
    } else if (buttonEvent == "morlinkClick") {
      setCustomDomainTabOn(false);
    }
  };

  return (
    <div>
      <div className="mySiteShareDrop" onClick={onShowModel}>
        <button className="flex items-center" type="button">
          <span>
            <svg
              class="sub-icon inline-block mr-1.5 -ml-0.5"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_156_54)">
                <path
                  d="M19 13V15.3077C19 17.3469 17.3469 19 15.3077 19H8.69231C6.65311 19 5 17.3469 5.00001 15.3077L5.00001 13"
                  stroke="#6F7582"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M8.25 8.76929L12 5.01929L15.75 8.76929"
                  stroke="#6F7582"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M12 5.01929V14.2501"
                  stroke="#6F7582"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_156_54">
                  <rect width="24" height="24" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </span>
          <span className="sharetxtlabel">
            {" "}
            {isLabel ? `${translate("Share")}` : null}
          </span>
        </button>
      </div>
      <Modal
        show={showShareModel}
        size={size}
        popup
        onClose={onCloseModel}
        className={locale === "ar" ? "ar-style" : ""}
      >
        <Modal.Header className="py-3 px-5 popTitle morLinkSharePop border-gray-200 border-b block">
          <div className="flex justify-between items-center">
            <span className="!text-base">
              {locale == "ar" || language == "ar"
                ? "شارك رابط مور لينك"
                : "Share your Mor link"}
            </span>
            {siteData?.custom_domain_name && siteData?.custom_domain_status ? (
              <div className="customNMorLinkBx flex text-sm border border-gray-200 rounded-lg h-10 px-2 py-1.5 mr-8">
                <button
                  className={`h-7 px-2.5 rounded-md mr-2 ${
                    customDomainTabOn ? "" : "active"
                  }`}
                  id="morlink"
                  type="button"
                  onClick={() => {
                    switchShareTab("morlinkClick");
                  }}
                >
                  Mor Link
                </button>
                <button
                  className={`h-7 px-2.5 rounded-md ${
                    customDomainTabOn ? "active" : ""
                  }`}
                  id="customdomain"
                  type="button"
                  onClick={() => {
                    switchShareTab("customdomainClick");
                  }}
                >
                  Custom domain
                </button>
              </div>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body className="pb-4 py-6 px-5 pt-6">
          <div className="flex">
            {userPlanType !== "FREEPLAN" ? (
              <div
                className="shareQrCode w-2/4 text-center relative before:border-r rtl:before:border-l rtl:before:border-r-0 rtl:before:left-00 before:border-gray-200 before:absolute 
            before:right-0 before:h-36 before:top-0 before:bottom-0 before:m-auto"
              >
                <h2 className="font-medium mb-2">
                  {locale == "ar" || language == "ar"
                    ? "مشاركة الرابط عن طريق الQR"
                    : "Share link with QR"}
                </h2>
                <div className="text-center border border-gray-200 inline-block rounded-lg relative ">
                  <div>
                    <QRCode
                      value={
                        customDomainTabOn
                          ? "www." + siteData?.custom_domain_name
                          : process.env.BASE_URL + siteUrl
                      }
                      size={150}
                      className="mx-auto"
                      // logoImage={siteData?.logo_url || logo?.src}
                      logoWidth={150 * 0.28}
                      fgColor="#38A376"

                      // removeQrCodeBehindLogo={true}
                      // logoPadding={0.2}
                    />
                    <Image
                      src={siteData?.logo_url || logo?.src}
                      alt="Logo"
                      width={42}
                      height={42}
                      style={{
                        borderRadius: "50%", // Make the logo round
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        // width: "40px", // Adjust the size as needed
                        // height: "40px", // Adjust the size as needed
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="shareRightBx w-2/4 sm:px-8 px-4 font-medium text-sm flex items-center">
              <div className="">
                <TwitterShareButton
                  url={`${
                    customDomainTabOn
                      ? "www." + siteData?.custom_domain_name
                      : process.env.BASE_URL + siteUrl
                  }`}
                  className="flex items-center !py-2.5"
                >
                  <TwitterIcon
                    size={24}
                    round={true}
                    className="mr-3 rtl:mr-0 rtl:ml-3"
                  />
                  {locale == "ar" || language == "ar"
                    ? "X “تويتر”"
                    : translate("X “Twitter”")}
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`${
                    customDomainTabOn
                      ? "www." + siteData?.custom_domain_name
                      : process.env.BASE_URL + siteUrl
                  }`}
                  className="flex items-center !py-2.5"
                >
                  <WhatsappIcon
                    size={24}
                    round={true}
                    className="mr-3 rtl:mr-0 rtl:ml-3"
                  />
                  {locale == "ar" || language == "ar"
                    ? "واتساب"
                    : translate("Whatsapp")}
                </WhatsappShareButton>
                <TelegramShareButton
                  url={`${
                    customDomainTabOn
                      ? "www." + siteData?.custom_domain_name
                      : process.env.BASE_URL + siteUrl
                  }`}
                  className="flex items-center !py-2.5"
                >
                  <TelegramIcon
                    size={24}
                    round={true}
                    className="mr-3 rtl:mr-0 rtl:ml-3"
                  />
                  {locale == "ar" || language == "ar"
                    ? "تليجرام"
                    : translate("Telegram")}
                </TelegramShareButton>
                <FacebookShareButton
                  url={`${
                    customDomainTabOn
                      ? "www." + siteData?.custom_domain_name
                      : process.env.BASE_URL + siteUrl
                  }`}
                  className="flex items-center !py-2.5"
                >
                  <FacebookIcon
                    size={24}
                    round
                    className="mr-3 rtl:mr-0 rtl:ml-3"
                  />
                  {locale == "ar" || language == "ar"
                    ? "فيسبوك"
                    : translate("Facebook")}
                </FacebookShareButton>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer dir="ltr" className="border-t border-gray-200 p-4 ">
          <span className="flex justify-between items-center w-full border border-gray-200 rounded-lg px-2">
            <span className="flex items-center !py-2.5 text-sm">
              <span className="">
                {customDomainTabOn ? null : process.env.BASE_URL}
                <span className="text-black-color-500">
                  {/* {process.env.BASE_URL + siteUrl || "-"} */}
                  {customDomainTabOn
                    ? "www." + siteData?.custom_domain_name
                    : siteUrl}
                </span>
              </span>
            </span>
            <span className="cursor-pointer" onClick={handleCopyClick}>
              <Tooltip content={translate(copyText)}>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2686_19001)">
                    <path
                      d="M10 13.877H3.33333V5.21038C3.33333 4.84371 3.03333 4.54371 2.66667 4.54371C2.3 4.54371 2 4.84371 2 5.21038V13.877C2 14.6104 2.6 15.2104 3.33333 15.2104H10C10.3667 15.2104 10.6667 14.9104 10.6667 14.5437C10.6667 14.177 10.3667 13.877 10 13.877ZM13.3333 11.2104V3.21038C13.3333 2.47704 12.7333 1.87704 12 1.87704H6C5.26667 1.87704 4.66667 2.47704 4.66667 3.21038V11.2104C4.66667 11.9437 5.26667 12.5437 6 12.5437H12C12.7333 12.5437 13.3333 11.9437 13.3333 11.2104ZM12 11.2104H6V3.21038H12V11.2104Z"
                      fill="#6F7582"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2686_19001">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.543701)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Tooltip>
            </span>
          </span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ShareModel;
