import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const mySwal = withReactContent(Swal);

export const createToast = (language) => {
  const position = language === "ar" ? "top-left" : "top-right";

  return mySwal.mixin({
    toast: true,
    position:  "top-right",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export const successAlert = (title, language) => {
  const Toast = createToast(language);
  Toast.fire({
    icon: "success",
    title,
    color: "#000",
    background: "#fff",
  });
};

export const errorAlert = (title, language) => {
  const Toast = createToast(language);
  Toast.fire({
    icon: "error",
    title,
    color: "#000",
    background: "#fff",
  });
};

export const warningAlert = (title, language) => {
  const Toast = createToast(language);
  Toast.fire({
    icon: "warning",
    title,
  });
};

// ... (other functions remain unchanged)
